import { graphql } from "gatsby";
import React from "react";
import BetterModels from "../../components/industries_Pages/BetterModels";
import HeroSection from "../../components/industries_Pages/HeroSection";
import AnnotationTypes from "../../components/industries_Pages/AnnotationTypes";
import Layout from "../../components/layout";
import RowsSection from "../../components/industries_Pages/RowsSection";
import "../../styles/page/annotation_pages.scss";
import SEO from "../../components/seo";
import CardSection from "../../components/industries_Pages/CardSection";
import TestimonialCardSection from "../../components/industries_Pages/TestimonialCardSection";
import { pickSlice } from "../../utilities/helpers";
import { getImage } from "gatsby-plugin-image";
import heroPoster from "../../assets/posters/industry-media.png";

const MediaEntertainmentPage = ({ data }) => {
  const _data = data?.prismicIndustryMediaEntertainmentPage?.data || {};
  const {
    trusted_brands_list = [[]],
    annotation_types = [[]],
    better_model = [[]],
    g2_rating = [[]],
    feature_cards = [[]],
  } = _data.body?.reduce((acc, v) => {
    acc[v.slice_type] = v;
    return acc;
  }, {});

  const isLarger = g2_rating?.items?.filter(
    (item) => item?.section_heading?.text?.length > 67
  );

  const page_section = pickSlice(_data, "page_section");

  return (
    <Layout mainClasses="">
      <article className="relative px-5 pt-32 mx-auto text-center max-w-7xl md:pt-44">
        <HeroSection
          trusted_brands_list={trusted_brands_list}
          imageSRC={_data?.hero_image}
          videoSRC={_data?.hero_video?.url}
          heading={_data?.page_heading?.html}
          description={_data?.page_description?.text}
          pageName="new image"
          videoPoster={heroPoster}
        />

        <section className="flex flex-wrap gap-x-5 gap-y-6 max-w-7xl justify-center lg:my-14 my-12">
          {feature_cards?.items?.map((item, index) => {
            const featureCardImage = getImage(item?.card_image);
            return (
              <CardSection
                key={index}
                imgAlt={""}
                imgSrc={featureCardImage}
                videoSrc={item?.card_video?.url}
                heading={item?.card_heading?.text}
              />
            );
          })}
        </section>
      </article>

      <section className="lg:mt-28 mt-12">
        <AnnotationTypes annotation_types={annotation_types} />
      </section>

      <div className="relative">
        <article className="relative px-5 mx-auto text-center max-w-7xl">
          <section className="flex lg:flex-row flex-col lg:gap-x-5 gap-y-6 max-w-7xl justify-center lg:mt-28 mt-12">
            {g2_rating?.items?.map((item, index) => {
              return (
                <TestimonialCardSection
                  key={index}
                  heading={item?.section_heading?.text}
                  description={item?.section_description?.text}
                  icon={null}
                  featureText={""}
                  learnMoreLink={item?.learn_more_link?.url}
                  isLarger={isLarger.length != 0 && true}
                />
              );
            })}
          </section>

          <section className="pb-12 lg:pb-28 lg:pt-12">
            {page_section?.map((item, index) => {
              const pageSectionImage = getImage(item?.primary?.section_image);
              return (
                item?.primary && (
                  <RowsSection
                    isReverse={index % 2 == 0 ? true : false}
                    btnLink={item?.primary?.learn_more_link?.url}
                    btnText="Learn more"
                    description={item?.primary?.section_description?.text}
                    heading={item?.primary?.section_heading?.text}
                    imgAlt={item?.primary?.section_image?.url || ""}
                    imgSrc={pageSectionImage}
                    videoSrc={item?.primary?.section_video?.url}
                    customClass={index != 0 && "lg:mt-0"}
                  />
                )
              );
            })}
          </section>
        </article>
        <section className="absolute bottom-0 clippy-bg lg:h-[1428px] h-[2460px] w-full -z-10"></section>
      </div>

      <BetterModels
        heading={better_model?.primary?.section_heading?.text}
        description={better_model?.primary?.section_description?.text}
        ctaHeading={_data?.cta_heading?.text}
        ctaDescription={_data?.cta_description?.text}
        modelTypes={better_model?.items}
        g2RatingData={better_model?.items}
        pageName="new image"
      />
    </Layout>
  );
};

export default MediaEntertainmentPage;

export const query = graphql`
  query {
    prismicIndustryMediaEntertainmentPage {
      data {
        meta_heading {
          text
          html
        }
        meta_description {
          text
          html
        }
        page_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        body {
          ... on PrismicIndustryMediaEntertainmentPageDataBodyPageSection {
            id
            primary {
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
                gatsbyImageData(placeholder: BLURRED)
              }
              section_description {
                html
                text
              }
              section_label {
                html
                text
              }
              section_video {
                url
              }
              learn_more_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicIndustryMediaEntertainmentPageDataBodyTrustedBrandsList {
            id
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              brand_logo {
                alt
                url
                dimensions {
                  width
                  height
                }
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicIndustryMediaEntertainmentPageDataBodyBetterModel {
            id
            primary {
              section_heading {
                text
                html
              }
              section_description {
                text
                html
              }
            }
            items {
              section_feature_heading {
                html
                text
              }
              section_feature_description {
                html
                text
              }
              section_feature_icon {
                alt
                url
              }
            }
            slice_type
          }
          ... on PrismicIndustryMediaEntertainmentPageDataBodyFeatureCards {
            id
            items {
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
                dimensions {
                  width
                  height
                }
                gatsbyImageData(placeholder: BLURRED)
              }
              card_video {
                url
              }
            }
            slice_type
          }
          ... on PrismicIndustryMediaEntertainmentPageDataBodyAnnotationTypes {
            id
            slice_type
            items {
              label {
                html
                text
              }
              label_icon {
                alt
                url
              }
              label_slide_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              label_slide_video {
                url
              }
            }
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicIndustryMediaEntertainmentPageDataBodyG2Rating {
            id
            items {
              icon {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              feature_text {
                html
                text
              }
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
            slice_type
          }
        }

        hero_video {
          url
        }
        hero_image {
          alt
          url
          dimensions {
            height
            width
          }
          gatsbyImageData(placeholder: BLURRED)
        }
        cta_description {
          text
          html
        }
        cta_heading {
          text
          html
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicIndustryMediaEntertainmentPage?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
