import React from "react";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";
import "../../styles/page/industries_page.scss"

const BetterModels = ({
  heading,
  ctaHeading,
  ctaDescription,
  modelTypes,
  pageName,
}) => {
  return (
    <article className="relative px-5 overflow-hidden sm:my-24 my-14">
      <section className="mx-auto max-w-7xl">
        <section className="z-10 flex flex-col justify-between text-black lg:items-center lg  :gap-y-18 gap-y-14">
          <h2 className="text-3xl sm:text-3.75xl leading-12 tracking-tightest font-semibold text-center">
            {heading}
          </h2>

          <section className="flex lg:flex-row lg:justify-between lg:gap-x-28 gap-y-14 flex-col lg:max-w-7xl max-w-xs mx-auto w-full">
            {modelTypes?.map((item, index) => {
              const {
                section_feature_icon,
                section_feature_heading,
                section_feature_description,
              } = item || {};
              return (
                <section
                  className="flex flex-col lg:items-start items-center max-w-2xl gap-5 lg:flex-row lg:max-w-md"
                  key={index}
                >
                  <div className="min-w-[80px] h-20 rounded-lg shadow-card flex justify-center better_model_icon">
                    <img
                      src={section_feature_icon?.url}
                      width={0}
                      height={0}
                      alt={section_feature_icon?.alt || "img"}
                      loading="lazy"
                      className="w-11 h-11 self-center"
                    />
                  </div>
                  <section className="text-center lg:text-left">
                    <h6 className="text-2xl font-semibold text-black leading-normal tracking-tighter_1 lg:-mr-10">
                      {section_feature_heading?.text}
                    </h6>
                    <p className="mt-2.5 text-base text-[#262626] sm:text-base lg:max-w-xs leading-5">
                      {section_feature_description?.text}
                    </p>
                  </section>
                </section>
              );
            })}
          </section>
        </section>

        <section className="w-full lg:px-32 lg:py-28 sm:px-9 px-5 py-16 mx-auto text-center text-[#FAFAFA] shadow-card max-w-7xl sm:mt-24 mt-14 cta_bg rounded-3xl border border-purple-200">
          <div className="w-full max-w-5xl">
            <h1 className="text-2xl sm:text-3.5xl font-semibold tracking-tighter_1">
              {ctaHeading}
            </h1>

            <p className="my-5 text-base lg:px-5 leading-7 max-w-[720px] mx-auto">
              {ctaDescription}
            </p>

            <section className="flex justify-center">
              <NewHomeLeadFormWithAPIForm
                pageName={pageName}
                btnText="Book a demo"
                isCustomBtnBg={true}
                btnColor="gradient_btn border-0 h-[50px]"
              />
            </section>
          </div>
        </section>
      </section>
    </article>
  );
};

export default BetterModels;
